import React from "react";

import HueImage from "../../images/Hue.png";

import PortfolioPage from "../../components/portfolioPage";

const links = [
	{
		endpoint: "https://github.com/FellowshipOfThePing/heyhue",
		title: "Github",
	},
];

const Hue = () => (
	<PortfolioPage
		image={HueImage}
		name="HeyHue"
		description="Simple CLI for controlling Philips Hue lights. I went on a brief binge with creating CLI tools when I realized how easy commander/inquirer make it. No more waiting for Siri to timeout 🙃,"
		links={links}
	/>
);

export default Hue;
